






















































































































































































import { appName, merchantURL, appUrl } from "@/env";
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IUserProfile } from "@/interfaces";
import { readUserProfile } from "@/store/main/getters";
import { dispatchPromoCosts } from "@/store/admin/actions";
import _ from "lodash";
import moment, { duration } from "moment-timezone";
import Apply from "../../Apply.vue";
import EditUser from "./EditUser.vue";
import UploadUserDoc from "./UploadUserDoc.vue";
import EditAddress from "./EditAddress.vue";
import AdminViewUser from "./AdminViewUser.vue";
import JsonCSV from "vue-json-csv";
import UploadReceipt from "./UploadReceipt.vue";

@Component({
  components: {
    Apply,
    JsonCSV,
    AdminViewUser,
    EditUser,
    UploadUserDoc,
    EditAddress,
    UploadReceipt,
  },
})
export default class SalesReport extends Vue {
  public dialog = false;

  public onlyAdmin = false;
  public onlyPaid = false;

  public headers() {
    return [
      {
        text: "Promo Code",
        sortable: true,
        value: "promo_code",
        align: "left",
      },
      {
        text: "Desc",
        value: "promo_desc",
        align: "left",
      },
      {
        text: "Promo Comment",
        value: "promo_comment",
        align: "left",
      },

      {
        text: "Month",
        sortable: true,
        value: "month",
        align: "left",
      },
      {
        text: "Year",
        sortable: true,
        value: "year",
        align: "left",
      },
      {
        text: "Amount",
        sortable: true,
        value: "amount",
        align: "left",
      },
      {
        text: "Created Date",
        value: "created_date",
        align: "left",
      },
      {
        text: "Updated Date",
        value: "updated_date",
        align: "left",
      },
    ];
  }

  public search = "";
  public search2 = "";
  public promo_costs = [];
  public userCSV = [];
  public promoCSV = [];
  public promos = [];
  public user_id = 0;
  public scroll_item = "";
  public onlyActive = true;
  public userToEdit = {};
  public dialogUploadReceipt = false;
  public receipt_id = "";

  $refs!: Vue["$refs"] & {
    upload_receipt: typeof UploadReceipt;
  };
  toggleOnlyActive() {
    this.onlyActive = !this.onlyActive;
  }
  stringify(item) {
    return JSON.stringify(item);
  }
  created() {
    if (location.host.startsWith("dev")) {
      document.title = "Dev: Building Status Report";
    } else {
      document.title = "Building Status Report";
    }
  }
  public async mounted() {
    const data = await dispatchPromoCosts(this.$store);
    const promo_costs = _.get(data, "data.data.promo_costs", []);
    this.promo_costs = promo_costs;

    const userFields = this.headers().map((header) => header.value);
    this.userCSV = this.promo_costs.map((user) => {
      const item = {};
      console.log(user);
      this.headers().forEach((header) => {
        item[header.text] = _.get(user, header.value, "");
      });
      return item;
    });
    console.log(data);
  }

  diffDates(date1, date2) {
    return moment(date2).diff(moment(date1), "days");
  }
  formatDate(date) {
    if (date) {
      return moment.utc(date).tz("Asia/Manila").format("MMM DD, YYYY HH:mm:ss");
    }
    return "";
  }
  formatDateOnly(date) {
    if (date) {
      return moment.utc(date).tz("Asia/Manila").format("MMM DD, YYYY");
    }
    return "";
  }
  async editItem(user_id, scroll_item = null) {
    this.user_id = user_id;
    this.scroll_item = scroll_item;
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  getPmethod(item) {
    if (item.billing) {
      return item.billing.ptype;
    }
    if (item.rebill) {
      return item.rebill.ptype;
    }
  }

  public async showUploadReceipt(user, receipt_id) {
    this.userToEdit = user;
    this.receipt_id = `${receipt_id}`;
    this.dialogUploadReceipt = true;
    setTimeout(() => {
      this.$refs.upload_receipt.reset();
    }, 100);
  }

  public async closeUploadReceipt(user_id) {
    this.dialogUploadReceipt = false;
    this.dialog = false;

    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }
}
